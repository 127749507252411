import { Template, Tournament, TournamentType } from '@/types/types';
import { Image, Tooltip } from '@nextui-org/react';
import { useCurrencyStore } from '@/store/useCurrencyStore';
import { useRouter } from 'next/router';
import CurrencyField from '../CurrencyField';
import { t } from 'i18next';

const TournamentHeader = ({
  template,
  hidePrize = false,
  tournamentType,
  showDuration,
  tournament,
}: {
  template: Template;
  hidePrize?: boolean;
  tournamentType?: TournamentType;
  showDuration?: boolean;
  tournament?: Tournament;
}) => {
  const router = useRouter();
  const { currency } = useCurrencyStore();

  return (
    <div className="flex items-center justify-between gap-2 text-white flex-1 min-w-0 w-full">
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center gap-2 flex-1">
          <Tooltip
            content={
              router.locale === 'en-US'
                ? template?.game_details?.game_name_en
                : template?.game_details?.game_name_ar
            }
            color="primary"
          >
            <Image
              src={template?.game_details?.game_mode_image}
              width={20}
              height={20}
              className="rounded-none"
              alt="Game Mode"
              disableSkeleton
            />
          </Tooltip>
          <span className="font-display text-lg font-medium text-start flex-1 truncate">
            {router.locale === 'en-US' ? template.name : template.name_ar}
          </span>
        </div>
        {showDuration &&
          (template?.league == 1 && tournament?.start ? (
            <span className="font-display text-base font-medium flex">
              {new Date(tournament?.start).toLocaleDateString('es-CL', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              })}
            </span>
          ) : (
            <span className="font-display text-base font-medium flex">
              {t('Duration')}
              {' : '}
              {router.locale === 'en-US'
                ? tournament?.durationTextEN
                : tournament?.durationTextAR}
            </span>
          ))}
      </div>

      {tournamentType != 'credits' && !hidePrize ? (
        <CurrencyField amount={+template.total_prize} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default TournamentHeader;
