import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

interface CurrencyState {
  currency: string;
  setCurrency: (currency: string) => void;
  latestRate: number;
  digit: number;
  setLatestRate: (rate: number, digit: number) => void;
}

export const useCurrencyStore = create<CurrencyState>()(
  persist(
    (set) => ({
      currency: 'USD',
      setCurrency: (currency) => set(() => ({ currency })),
      latestRate: 1,
      digit: 1,
      setLatestRate: (rate, digit) => set(() => ({ latestRate: rate, digit })),
    }),
    {
      name: 'currency',
      storage: createJSONStorage(() => localStorage),
      partialize: (state) => ({ currency: state.currency }),
    },
  ),
);
