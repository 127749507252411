import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
} from '@nextui-org/react';
import { t } from 'i18next';
import { useSession } from 'next-auth/react';
import Link from 'next/link';
import { useRouter } from 'next/router';

import useCheckDisclamer from '../../hooks/useCheckDisclamer';
import useHasLiveTours from '../../hooks/useHasLiveTours';
import useNavbarLinks from '../../hooks/useNavbarLinks';

import useTracker, { TrakerTypes } from '../../hooks/useTraker';
import Searchbar from '../layout/cards/Searchbar';
import CurrencySwitcher from './CurrencySwitcher';
import GoToGPartnerButton from './GoToGPartnerButton';
import LangSwitcher from './LangSwitcher';
import LoginButtonWithMenu from './LoginButtonWithMenu';
import Logo from './Logo';
import Notifications from './Notifications';
import SearchButton from './SearchButton';
import ToggleThemeButton from './ToggleThemeButton';
import UnAuthMobileMenu from './UnAuthMobileMenu';
export default function MainNavbar() {
  const router = useRouter();
  const { asPath } = router;
  const { status } = useSession();
  const { isData } = useCheckDisclamer();
  const isLoggedIn = status === 'authenticated';
  const isAuthenticated = isLoggedIn && !isData?.banned;
  const { hasLive } = useHasLiveTours();
  const trackTournamentPage = useTracker({
    key: TrakerTypes.TournamentPageVisited,
  });

  return (
    <Navbar
      maxWidth="full"
      className="shadow-md z-50 bg-[#f7f5ff] dark:bg-jacarta-900 dark:bg-transparent"
      classNames={{
        item: [
          'cursor-pointer font-semibold items-center hover:text-accent transition-all',
        ],
      }}
    >
      <NavbarBrand className="cursor-pointer basis-auto grow-0">
        <Logo />
      </NavbarBrand>
      {isLoggedIn ? null : (
        <NavbarContent
          className="hidden lg:flex gap-2 xl:gap-4"
          justify="start"
        >
          <NavbarItem className={`${asPath === '/' ? 'text-accent' : ''}`}>
            <Link href="/">{t('Home')}</Link>
          </NavbarItem>

          <NavbarItem
            className={`border-2 border-accent-dark rounded-full px-3 py-.5 ${asPath.includes('/dashboard') || asPath === '/tournaments' ? 'text-accent' : ''} ${hasLive ? 'animate-border-color' : ''} `}
            onClick={trackTournamentPage}
          >
            <Link
              href={
                status === 'authenticated'
                  ? `/dashboard${hasLive ? '?t=live' : ''}`
                  : '/tournaments'
              }
            >
              {t('Tournaments')}
            </Link>
          </NavbarItem>

          <NavbarItem
            className={`${asPath === '/leaderboard' ? 'text-accent' : ''}`}
          >
            <Link href="/leaderboard">{t('Leaderboard')}</Link>
          </NavbarItem>
          <NavbarItem
            className={`${asPath === '/leaderboard' ? 'text-accent' : ''}`}
          >
            <Link href="/contact">{t('Contact_Us')}</Link>
          </NavbarItem>
        </NavbarContent>
      )}

      <NavbarContent className="flex gap-2 xl:gap-4 items-center" justify="end">
        <NavbarItem className="hover:text-current xl:hidden">
          <SearchButton />
        </NavbarItem>
        <NavbarItem className="hover:text-current hidden xl:block">
          <Searchbar />
        </NavbarItem>
        {isAuthenticated && (
          <NavbarItem>
            <Notifications />
          </NavbarItem>
        )}
        <NavbarItem
          className={`${isAuthenticated ? 'order-1 ' : 'lg:order-none'}  `}
        >
          <LoginButtonWithMenu />
        </NavbarItem>
        {!isAuthenticated && (
          <NavbarItem className="order-1">
            <UnAuthMobileMenu isData={isData} />
          </NavbarItem>
        )}

        {isAuthenticated && (
          <NavbarItem>
            <GoToGPartnerButton isData={isData} />
          </NavbarItem>
        )}
        <NavbarItem className="hidden lg:block">
          <CurrencySwitcher />
        </NavbarItem>
        <NavbarItem className="hidden lg:block">
          <LangSwitcher />
        </NavbarItem>
        <NavbarItem>
          <ToggleThemeButton />
        </NavbarItem>
      </NavbarContent>
    </Navbar>
  );
}

const isHelpCenterPath = (path) => {
  return path === '/faq' || path === '/contact';
};
const isLearnMorePath = (path) => {
  const paths = [
    '/how-to-win',
    '/affiliate-program',
    '/matchmaking-rating',
    '/gamerg-requests',
  ];

  return paths.includes(path);
};
