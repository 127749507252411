import { useMemo } from 'react';
import { useCurrencyStore } from '../store/useCurrencyStore';
import { cn } from '@nextui-org/react';
import { motion, AnimatePresence } from 'framer-motion';

const CurrencyField = ({
  amount,
  className,
  hideTilde = false,
  unStyled = false,
  withBraces = false,
}: {
  amount: number;
  className?: string;
  hideTilde?: boolean;
  unStyled?: boolean;
  withBraces?: boolean;
}) => {
  const currency = useCurrencyStore((state) => state.currency);
  const latestRate = useCurrencyStore((state) => state.latestRate);
  const digit = useCurrencyStore((state) => state.digit);

  const converted = useMemo(() => {
    if (latestRate > 0 && amount > 0)
      return (Math.round(amount * latestRate * 10) / 10).toFixed(digit);

    return 0;
  }, [amount, latestRate, digit]);

  return (
    <AnimatePresence>
      {currency != 'USD' && (
        <motion.span
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.1 }}
          className={cn(
            !unStyled &&
              'items-center bg-jacarta-100 text-black rounded-full px-2.5 py-1 text-xs font-semibold whitespace-nowrap font-display',
            className,
          )}
        >
          {withBraces && '('}
          {!hideTilde && '~'}
          {converted} {currency}
          {withBraces && ')'}
        </motion.span>
      )}
    </AnimatePresence>
  );
};

export default CurrencyField;
