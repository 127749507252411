import axios, { AxiosRequestConfig } from 'axios';
import { getSession } from 'next-auth/react';

let dev = false;
// dev = true;
const baseURL = !dev
  ? process.env.NEXT_PUBLIC_NODE_API
  : 'http://127.0.0.1:3010/api/v2/';

const Axios = axios.create({
  baseURL,
  validateStatus(status) {
    return status < 501;
  },
});

Axios.interceptors.request.use(async (config) => {
  const session = await getSession();
  config.headers.Secure = session?.secure;
  return config;
});

const axiosAPI = {
  get: async <T>(path: string, config?: AxiosRequestConfig): Promise<T> => {
    const { data } = await Axios.get<T>(path, config);
    return data;
  },
  post: async <T>(
    path: string,
    body?: Record<string, any>,
    config?: AxiosRequestConfig,
  ): Promise<T> => {
    const { data } = await Axios.post<T>(path, body, config);
    return data;
  },
  put: async <T>(
    path: string,
    body?: Record<string, any>,
    config?: AxiosRequestConfig,
  ): Promise<T> => {
    const { data } = await Axios.put<T>(path, body, config);
    return data;
  },
  delete: async <T>(path: string, config?: AxiosRequestConfig): Promise<T> => {
    const { data } = await Axios.delete<T>(path, config);
    return data;
  },
};

export default axiosAPI;
