import useSafePush from '@/hooks/useSafePush';
import {
  setDashboardTab,
  useAppDispatch,
  useAppSelector,
} from '@/redux/counterSlice';
import { useLiveStore } from '@/store/useLiveStore';
import { LiveTournament } from '@/types/types';
import { Button, cn, Spinner } from '@nextui-org/react';
import { t } from 'i18next';
import LiveCard from './liveCard';
import useLiveTournaments from '@/hooks/useLiveTournaments';
import useEmblaCarousel from 'embla-carousel-react';
import { EmblaCarouselType } from 'embla-carousel';
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures';
import { useCallback, useEffect, useState } from 'react';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';

const LiveTournamentSelector = ({ onClose }: { onClose?: () => void }) => {
  const dispatch = useAppDispatch();
  const { safePush } = useSafePush();
  const { dashboardTab: tab } = useAppSelector((state) => state.counter);
  const { tournamentId, setTournamentId, bracketId, setBracketId } =
    useLiveStore();

  const tournaments = useLiveTournaments();

  const onTournamentChange = (v: LiveTournament) => {
    setTournamentId(v.play_id);
    setBracketId(v.bracket_id);

    if (tab != 'live') {
      dispatch(setDashboardTab('live'));
      safePush({ query: { t: 'live' } });
    }

    onClose?.();
  };

  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      align: 'center',
      axis: 'y',
      dragFree: true,
    },
    [WheelGesturesPlugin()],
  );

  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onSelect(emblaApi);
    emblaApi.on('reInit', onSelect).on('select', onSelect);
  }, [emblaApi, onSelect]);

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollPrev();
  }, [emblaApi]);

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollNext();
  }, [emblaApi]);

  return (
    <>
      <h2 className="text-lg px-5 py-1 my-2  text-center font-display">
        {t('Joined Tournaments')}
      </h2>

      <div className="flex flex-col items-center">
        {tournaments.isRefetching ? (
          <Spinner size="sm" className="my-10 mx-auto" />
        ) : (
          tournaments.data?.length == 0 && (
            <div className="text-center font-semibold text-base pt-2">
              {t("You've not joined any tournament yet")}
            </div>
          )
        )}

        <section className="embla w-full">
          <div className="overflow-hidden" ref={emblaRef}>
            <div
              className={cn(
                'flex flex-col touch-pan-y touch-pinch-zoom cursor-grab select-none  max-h-[40vh]',
              )}
            >
              {!tournaments.isRefetching &&
                tournaments.data?.map((v) => (
                  <div
                    key={`${v.play_id}x${v.bracket_id}`}
                    className={cn(
                      'p-1.5',

                      tournamentId == v.play_id && bracketId == v.bracket_id
                        ? 'bg-accent rounded-md transition-colors'
                        : 'grayscale',
                    )}
                  >
                    <LiveCard
                      tournament={v}
                      onSelect={() => onTournamentChange(v)}
                    />
                  </div>
                ))}
            </div>
          </div>

          {(!prevBtnDisabled || !nextBtnDisabled) && (
            <div className="flex items-center justify-between mt-2 mb-1">
              <span className="font-display">
                {tournaments.data?.length} {t('tournaments')}
              </span>
              <div className="flex items-center gap-1">
                <Button
                  className="h-6"
                  variant="bordered"
                  color="primary"
                  isIconOnly
                  size="sm"
                  onPress={onPrevButtonClick}
                  isDisabled={prevBtnDisabled}
                >
                  <BiChevronUp size={20} />
                </Button>
                <Button
                  className="h-6"
                  variant="bordered"
                  color="primary"
                  isIconOnly
                  size="sm"
                  onPress={onNextButtonClick}
                  isDisabled={nextBtnDisabled}
                >
                  <BiChevronDown size={20} />
                </Button>
              </div>
            </div>
          )}
        </section>
      </div>
    </>
  );
};

export default LiveTournamentSelector;
