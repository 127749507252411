import { Button, cn, Link } from '@nextui-org/react';
import { t } from 'i18next';
import { SiDiscord } from 'react-icons/si';

export default function DiscordButton({
  text = 'Join the Community',
  isIconOnly = false,
  className = '',
}) {
  return (
    <Button
      size="md"
      className={cn(
        'bg-[#5865f2] text-white rounded-full font-semibold',
        className,
      )}
      as={Link}
      target="_blank"
      href="https://discord.gg/uSJkN95JxW"
      startContent={<SiDiscord size={18} />}
      isIconOnly={isIconOnly}
    >
      {!isIconOnly && t(text)}
    </Button>
  );
}
