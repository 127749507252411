import Link from 'next/link';
import Logo from './navbar/Logo';
import { Image } from '@nextui-org/react';
import { useTranslation } from 'react-i18next';
import SocialMediaIcons from './socialMediaIcons';
import FloatingButtons from './FloatingButtons';

const Footer = () => {
  const { t } = useTranslation();

  const footerMenuList = [
    {
      id: 1,
      title: t('Gaming_Core'),
      list: [
        {
          id: 1,
          href: '/tournaments',
          text: t('Tournaments'),
        },
        {
          id: 2,
          href: '/leaderboard',
          text: t('Leaderboard'),
        },
      ],
    },
    {
      id: 2,
      title: t('Help_Center'),
      list: [
        {
          id: 2,
          href: '/contact',
          text: t('Contact_Us'),
        },
      ],
    },
  ];

  return (
    <div>
      <FloatingButtons />

      <footer className="dark:bg-jacarta-900 page-footer border-jacarta-100 dark:border-jacarta-600 border-t-2 bg-white">
        <div className="p-4 md:p-6">
          <div className="flex flex-col gap-5 xl:flex-row">
            <div className="grow pt-5 md:pt-0">
              <Logo />

              <p className="dark:text-jacarta-300 font-display mt-2 md:mt-0">
                {t('Connect_Play_Repeat')}
              </p>

              <SocialMediaIcons className="my-5" />
            </div>

            <div className="flex w-full flex-wrap justify-center gap-10 px-2 pb-2 *:grow sm:flex-nowrap lg:max-w-[50%] lg:justify-between lg:pe-8 xl:pt-8">
              {footerMenuList.map((single) => (
                <div key={single.id}>
                  <h3 className="font-display text-jacarta-700 mb-4 text-sm dark:text-white">
                    {single.title}
                  </h3>
                  <ul className="dark:text-jacarta-300 flex flex-col space-y-1">
                    {single.list.map((item) => {
                      const { id, href, text } = item;
                      return (
                        <li key={id}>
                          <Link
                            href={href}
                            className="text-sm transition-colors hover:text-accent dark:hover:text-white"
                          >
                            {text}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ))}
            </div>
          </div>

          <p className="my-4">{t('Riot_Disclaimer')}</p>

          <div>
            <Link
              href="https://www.1d3.com/"
              className="flex items-center justify-center transition-colors hover:text-accent dark:hover:text-white"
              target="_blank"
            >
              <Image
                src="/images/distributors/logo-black.svg"
                width={128}
                className="inline-block max-h-7 dark:hidden"
                alt={'1D3'}
              />
              <Image
                width={128}
                src="/images/distributors/logo-white.svg"
                className="hidden max-h-7 dark:block"
                alt={'1D3'}
              />
              <span className="pt-3 text-center">{t('1D3_Disclaimer')} </span>
            </Link>
          </div>

          <div className="flex flex-col items-center justify-between space-y-2 py-8 sm:flex-row sm:space-y-0">
            <span className="dark:text-jacarta-400 text-sm">
              <span>
                &copy; {new Date().getFullYear()} &nbsp;
                <Link href="https://www.gamerg.gg">{t('Gamerg')}</Link>
              </span>
            </span>

            <ul className="dark:text-jacarta-400 flex flex-wrap gap-5 text-sm">
              <li>
                <Link
                  href="/terms-and-conditions"
                  className="transition-colors hover:text-accent dark:hover:text-white"
                >
                  {t('Terms_and_Conditions')}
                </Link>
              </li>

              <li>
                <Link
                  href="/privacy-policy"
                  className="transition-colors hover:text-accent dark:hover:text-white"
                >
                  {t('Privacy_Policy')}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
