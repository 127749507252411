export const amountChange = (value: string) => {
  if (value == '') return value;

  if (Number(value) < 0 || isNaN(Number(value))) return null;

  let num = value;
  const splitted = num.split('.');
  if (splitted[1]?.length > 2) {
    num = splitted[0] + '.' + splitted[1].slice(0, 2);
  }

  return num;
};

export const getFirstDayOfMonth = () => {
  const date = new Date();
  date.setDate(1);
  return date.toISOString().split('T')[0];
};

export const getToday = () => new Date().toISOString().split('T')[0];

export const containsUrl = (text: string): boolean => {
  const urlPattern =
    /(?:https?:\/\/|www\.|ws:\/\/|\b[a-z0-9]+\.(?:com|net|org|io|co|uk|edu|gov|tk|buzz|xyz|top|ga|ml|info|cf|gq|icu|gg|wang|live|cn|online|host|us|ru|store|in|fr|pl|id|beauty|eu|rip)\b)/i;

  return urlPattern.test(text);
};

export const formatNumber = (num: number | string) =>
  new Intl.NumberFormat().format(+num);

export const getLetterColor = (letter: string) => {
  const competitive_colors = [
    { letter: 'A', color: '#C41E3A' },
    { letter: 'B', color: '#1C39BB' },
    { letter: 'C', color: '#76FF7A' },
    { letter: 'D', color: '#5D3FD3' },
    { letter: 'E', color: '#00FF80' },
    { letter: 'F', color: '#FF007F' },
    { letter: 'G', color: '#FFC300' },
    { letter: 'H', color: '#00E5FF' },
    { letter: 'I', color: '#7F00FF' },
    { letter: 'J', color: '#FF4500' },
    { letter: 'K', color: '#A52A2A' },
    { letter: 'L', color: '#6A5ACD' },
    { letter: 'M', color: '#FF1493' },
    { letter: 'N', color: '#00CED1' },
    { letter: 'O', color: '#FF6347' },
    { letter: 'P', color: '#FF4500' },
    { letter: 'Q', color: '#006400' },
    { letter: 'R', color: '#4682B4' },
    { letter: 'S', color: '#4B0082' },
    { letter: 'T', color: '#9400D3' },
    { letter: 'U', color: '#008000' },
    { letter: 'V', color: '#FF5733' },
    { letter: 'W', color: '#1E90FF' },
    { letter: 'X', color: '#FF69B4' },
    { letter: 'Y', color: '#32CD32' },
    { letter: 'Z', color: '#228B22' },
  ];
  return competitive_colors.find((c) => c.letter === letter?.toUpperCase())
    ?.color;
};
