import cookies from 'js-cookie';
import nodeAPI from '@/lib/nodeapi';
import { useQuery } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';

export default function useConnectAffiliate() {
  const { data: session, status } = useSession();

  return useQuery({
    queryKey: ['Connect Affiliate', session?.secure],
    queryFn: async () => {
      const cookie = cookies.get('affiliateAccount');
      if (!cookie) return false;

      const { status } = await nodeAPI(
        'POST',
        session?.secure,
        'user/connectAffiliate',
        {
          invitee_username: cookie,
        },
      );

      if (status) cookies.remove('affiliateAccount');

      return true;
    },
    enabled: status == 'authenticated',
  });
}
