import nodeAPI from '@/lib/nodeapi';
import { Spinner } from '@nextui-org/react';
import { useQuery } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';
import { useTranslation } from 'react-i18next';
import Flag from 'react-world-flags';
import Link from 'next/link';
import { UserImage } from '../dashboard/live/userAvatar';

const TopWeeklyEarners = () => {
  const { t } = useTranslation();
  const { data: session, status } = useSession();

  const {
    data: users,
    isLoading,
    isError,
    error,
  } = useQuery<
    {
      username: string;
      profile_pic: string;
      country_code: string;
      total: number;
    }[]
  >({
    queryKey: ['Top GamerG Winners', session?.secure],
    queryFn: async () => {
      const { data, status } = await nodeAPI(
        'GET',
        session?.secure,
        'extra/topEarners',
      );

      if (!status) throw new Error(t('No earners yet this week') as string);

      return data ?? [];
    },
    enabled: status == 'authenticated',
  });

  return (
    <>
      <h2 className="text-lg  px-5 py-1 my-2  text-center font-display">
        {t(`Top GamerG Winners`)}
      </h2>
      <div className="flex flex-col items-center gap-2 w-full">
        {isLoading && <Spinner size="sm" className="my-10" />}
        {isError && <p className="my-10 px-5 text-center">{error.message}</p>}

        {users?.map((u) => (
          <div
            key={u.username}
            className="flex items-center justify-between gap-2 w-full border border-jacarta-200 dark:border-jacarta-600 rounded-md p-1"
          >
            <Link
              className="flex items-center gap-2 w-max"
              href={`/u/${u?.username}`}
            >
              <UserImage user={u} hasBorder />

              <div className="flex flex-col items-start justify-center -space-y-1 flex-1">
                <span className="font-display text-medium">{u?.username}</span>
                <Flag
                  code={u?.country_code}
                  style={{
                    width: '24px',
                    height: '24px',
                  }}
                />
              </div>
            </Link>

            <span className="text-green-success font-display text-lg me-3">
              ${Number(u.total).toFixed(0)}
            </span>
          </div>
        ))}
      </div>
    </>
  );
};

export default TopWeeklyEarners;
