import { useQuery } from '@tanstack/react-query';
import cookies from 'js-cookie';
import { useCurrencyStore } from '../store/useCurrencyStore';
import axiosAPI from './axiosAPI';

interface Currency {
  currency_code: string;
  currency_name: string;
  currency_name_ar: string;
  rate: number;
  digit: number;
}

const useCurrencyRate = () => {
  const setLatestRate = useCurrencyStore((state) => state.setLatestRate);

  const { data, isLoading } = useQuery({
    queryKey: ['getAllCurrencies'],
    queryFn: async () => {
      const { results } = await axiosAPI.get<{ results: Currency[] }>(
        'extra/getAllCurrencies',
      );

      return results;
    },
    staleTime: 1000 * 60 * 60,
  });

  const currency = data?.find(
    ({ currency_code }) => currency_code == (cookies?.get('currency') || 'USD'),
  );
  if (currency) setLatestRate(currency.rate, currency.digit);

  return { data, isLoading };
};

export default useCurrencyRate;
