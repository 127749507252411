import CurrencyField from '@/components/CurrencyField';
import { CardTooltip } from './CardTooltip';
import { t } from 'i18next';


export const PrizeCard = ({ balance }: { balance: number }) => {
  return (
    <div className="flex  gap-2 flex-col flex-1">
      <div className="flex items-center justify-between relative">
        <p className={`text-sm font-semibold text-accent`}>{t('Prizes')}</p>
        <CardTooltip
          text={t('Anything you win from Tournaments will be earned here')}
        />
      </div>

      <div
        className={`rounded-md relative text-center py-2 border text-accent border-accent px-2 w-full
        `}
      >
        <p className={`text-md font-semibold text-accent}`}>
          {balance ?? '0.00'} $
        </p>
      </div>

      <CurrencyField amount={balance ?? 0} />
    </div>
  );
};
