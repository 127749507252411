import axiosAPI from '@/lib/axiosAPI';
import { Announcement } from '@/types/types';
import { useQuery } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';

const useAnnouncements = () => {
  const { status } = useSession();

  return useQuery({
    queryKey: ['announcements'],
    queryFn: async () => {
      const { message } = await axiosAPI.get<{
        status: boolean;
        message: Announcement;
      }>('extra/getAnnouncement');

      return message;
    },
    placeholderData: {
      announcement_en: '',
      announcement_ar: '',
      created_at: '',
    },
    enabled: status == 'authenticated',
  });
};


export default useAnnouncements;
