import '@/styles/globals.css';

import { Montserrat, SpaceGrotesk, Tajawal } from '@/lib/fonts';
import { NextUIProvider } from '@nextui-org/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import cookies from 'js-cookie';
import { SessionProvider } from 'next-auth/react';
import { appWithTranslation } from 'next-i18next';
import { ThemeProvider } from 'next-themes';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Layout from '../components/layout';
import Meta from '../components/Meta.jsx';
import i18n from '../lib/i18n.js';
import nextI18NextConfig from '../next-i18next.config.js';
import { store } from '../redux/store';
import Analytics from '@/components/Analytics';

import 'react-toastify/dist/ReactToastify.css';

const FullLoader = dynamic(() => import('@/components/fullLoading.jsx'), {
  suspense: true,
});

function MyApp({ Component, pageProps: { session, ...pageProps } }) {
  const [initialized, setInitialized] = useState(false);
  const queryClient = new QueryClient();
  const router = useRouter();

  useEffect(() => {
    setInitialized(true);

    if (router.isReady) {
      const cookieLanguage = cookies.get('i18next');
      const desiredLanguage = cookieLanguage || router.locale;

      i18n.changeLanguage(desiredLanguage).then(() => {
        cookies.set('i18next', desiredLanguage);
        document.body.dir = desiredLanguage === 'ar-SA' ? 'rtl' : 'ltr';

        const { pathname, query } = router;

        if (desiredLanguage !== router.locale) {
          router.replace({ pathname, query }, undefined, {
            locale: desiredLanguage,
          });
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  if (!initialized) return <FullLoader />;

  return (
    <>
      <Meta title="GAMERG" />

      <Analytics />

      <main
        className={`${Montserrat.variable} ${SpaceGrotesk.variable} ${Tajawal.variable}`}
      >
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <SessionProvider session={session}>
              <NextUIProvider>
                <ThemeProvider enableSystem={true} attribute="class">
                  <Layout>
                    <Component {...pageProps} />
                  </Layout>
                  <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    closeOnClick
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    limit={5}
                    // if they asked for it so so be it
                    // rtl={false}
                    // hideProgressBar={false}
                    // newestOnTop={false}
                    // theme="light"
                    // transition={'Bounce'}
                  />
                </ThemeProvider>
              </NextUIProvider>
            </SessionProvider>
          </Provider>

          <ReactQueryDevtools
            initialIsOpen={false}
            buttonPosition="bottom-left"
          />
        </QueryClientProvider>
      </main>
    </>
  );
}

export default appWithTranslation(MyApp, nextI18NextConfig);
