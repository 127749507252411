import { useMutation } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';
import Head from 'next/head';
import Script from 'next/script';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import nodeAPI from '../../../lib/nodeapi';
import { _1D3ModalHide } from '../../../redux/counterSlice';
import { useBalanceStore } from '../../../store/useBalance';

const _1D3Modal = () => {
  const { setBalance, balance } = useBalanceStore();
  const { data: session } = useSession();
  const dispatch = useDispatch();
  const { _1D3Url } = useSelector((state) => state.counter);

  const { mutateAsync } = useMutation({
    mutationKey: ['Balance'],
    mutationFn: async () => {
      const response = await nodeAPI(
        'POST',
        session.secure,
        'extra/checkUserBalance',
      );

      setBalance({
        ...balance,
        depositBalance: response.depositBalance,
        prizeUserBalance: response.prizeUserBalance,
      });
    },
  });

  useEffect(() => {
    try {
      if (!_1D3Url) return;

      EPayWidget?.run(
        {
          baseUrl: `${_1D3Url}&frame_mode=popup`,
          onExit: async () => {
            await mutateAsync();
            dispatch(_1D3ModalHide());
          },
        },
        'get',
      );
    } catch (err) {
      console.error(err);
    }
  }, [_1D3Url, mutateAsync, dispatch]);

  return (
    <>
      <Script
        src="https://checkout.1d3.com/shared/merchant.js"
        strategy="lazyOnload"
      />
    </>
  );
};

export default _1D3Modal;
