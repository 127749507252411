import LiveTournamentSelector from '@/components/dashboard/live/liveTournamentSelector';
import { useRouter } from 'next/router';
import TopWeeklyEarners from '../../play/topWeeklyEarners';

const DefaultEndbar = () => {
  const { asPath } = useRouter();

  const isTournamentsOrLive =
    asPath === '/dashboard' || asPath === '/dashboard?t=live';

  return (
    <>
      {isTournamentsOrLive && <LiveTournamentSelector />}
      <TopWeeklyEarners />
    </>
  );
};

export default DefaultEndbar;
