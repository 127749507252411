import useNavbarLinks from '@/hooks/useNavbarLinks';
import { useControlPanelStore } from '@/store/useControlPanelStore';
import { Button, Link } from '@nextui-org/react';
import { useRouter } from 'next/router';

export const SidebarNavlist = () => {
  const { push } = useRouter();

  const { sidebarListData } = useNavbarLinks();
  const { setIsOpen } = useControlPanelStore();

  return (
    <nav className="flex flex-col gap-2 items-start ">
      {sidebarListData.map((item, i) => (
        <Button
          key={i}
          variant="light"
          className="text-jacarta-700 dark:text-jacarta-50 font-semibold rounded-sm hover:bg-transparent data-[hover=true]:bg-transparent hover:underline text-start lg:text-center h-7"
          as={item.action ? 'button' : Link}
          href={item.href}
          onPress={() => {
            if (item.action) item.action();
            setIsOpen(false);
          }}
          startContent={item.icon}
        >
          {item.label}
        </Button>
      ))}
    </nav>
  );
};
