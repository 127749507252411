import useAnnouncements from '@/hooks/query-hooks/useAnnouncements';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { motion } from 'motion/react';

const Announcements = () => {
  const router = useRouter();
  const { data } = useAnnouncements();
  const { t } = useTranslation();

  if (
    (router.locale == 'en-US' && data?.announcement_en?.trim() == '') ||
    (router.locale == 'ar-SA' && data?.announcement_ar?.trim() == '')
  )
    return;

  return (
    <motion.div
      className="flex flex-col gap-1 border-2 border-[#ffe69c] bg-[#fff3cd] rounded-md p-2 font-display w-full text-[#664d03]"
      initial={{ opacity: 0, y: 200 }}
      animate={{ opacity: 1, y: 0 }}
    >
      <h3>{t(`Announcements`)}</h3>
      <p className="text-sm">
        {router.locale === 'en-US'
          ? data?.announcement_en
          : data?.announcement_ar}
      </p>
    </motion.div>
  );
};

export default Announcements;

