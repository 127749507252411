import { useRouter } from 'next/router';
import { cn, Select, SelectItem } from '@nextui-org/react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Button,
  Skeleton,
} from '@nextui-org/react';
import useCurrencyRate from '@/lib/useCurrencyRate';
import { useCurrencyStore } from '@/store/useCurrencyStore';

const CurrencySwitcher = ({ inControlMenu }) => {
  const router = useRouter();
  const currency = useCurrencyStore((state) => state.currency);
  const setCurrency = useCurrencyStore((state) => state.setCurrency);

  const { data, isLoading } = useCurrencyRate();

  const handleSetCurrency = (currencyCode: string) => {
    setCurrency(currencyCode);
  };

  return (
    <>
      {/* <!-- CURRENCY Select --> */}

      {data ? (
        <Select
          onSelectionChange={(e) => {
            handleSetCurrency(e.currentKey!);
          }}
          defaultSelectedKeys={[currency]}
          size="sm"
          className={cn('flex-1 lg:hidden', inControlMenu ? 'lg:flex' : '')}
          radius="full"
          aria-label="currency-switcher"
        >
          {data?.map((element) => (
            <SelectItem
              key={element.currency_code}
              value={element.currency_code}
              aria-label={element.currency_name ?? 'currency-filed'}
              // className="text-jacarta-50 "
            >
              {router.locale === 'en-US'
                ? element.currency_name
                : element.currency_name_ar}
            </SelectItem>
          ))}
        </Select>
      ) : null}

      {/* <!-- CURRENCY Menu --> */}

      <Dropdown
        classNames={{
          content: 'menu-gradient',
        }}
      >
        <DropdownTrigger
          className={cn('hidden lg:flex', inControlMenu ? 'lg:hidden' : '')}
        >
          <Button
            size="sm"
            isIconOnly
            variant="faded"
            radius="full"
            className={'menu-icon-button text-[10px]'} // in global css
            aria-label="currency-switcher"
          >
            {currency}
          </Button>
        </DropdownTrigger>
        <DropdownMenu
          aria-label="lang-switcher-menu"
          variant="flat"
          onAction={(e) => handleSetCurrency(e.toString())}
          itemClasses={{
            base: [
              'text-jacarta-800 dark:text-jacarta-50',

              'data-[hover=true]:bg-jacarta-100',
              'dark:data-[hover=true]:bg-jacarta-700',
            ],
          }}
        >
          {
            data?.map((element) => (
              <DropdownItem key={element.currency_code}>
                {router.locale === 'en-US'
                  ? element.currency_name
                  : element.currency_name_ar}
              </DropdownItem>
            )) as any
          }
          {isLoading && !data ? (
            <>
              <Skeleton className="h-3 w-3/5 rounded-md " />
              <Skeleton className="h-3 w-2/5 rounded-md " />
              <Skeleton className="h-3 w-4/5 rounded-md " />
            </>
          ) : null}
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default CurrencySwitcher;
