import DiscordButton from './navbar/DiscordButton';
import {
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@nextui-org/react';
import { FaInfo } from 'react-icons/fa6';
import Announcements from './play/announcements';
import useAnnouncements from '@/hooks/query-hooks/useAnnouncements';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

export default function FloatingButtons() {
  const router = useRouter();
  const { data } = useAnnouncements();
  const [isOpen, setIsOpen] = useState(false);

  const onResize = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, []);

  return (
    <div className="fixed bottom-4 end-4 z-[49] md:hidden flex flex-col gap-2">
      {!(
        (router.locale == 'en-US' && data?.announcement_en.trim() == '') ||
        (router.locale == 'ar-SA' && data?.announcement_ar.trim() == '')
      ) && (
        <Popover
          placement="top-end"
          size="lg"
          classNames={{
            content: '!p-0 rounded-md w-72 xs:w-80',
          }}
          isOpen={isOpen}
          onOpenChange={setIsOpen}
        >
          <PopoverTrigger>
            <Button
              variant="faded"
              size="md"
              className="bg-[#fff3cd] text-[#664d03] border-[#ffe69c]"
              radius="full"
              startContent={<FaInfo size={18} />}
              isIconOnly
            />
          </PopoverTrigger>
          <PopoverContent>
            <Announcements />
          </PopoverContent>
        </Popover>
      )}

      <DiscordButton isIconOnly />
    </div>
  );
}
